<script setup>
import { ref, inject } from 'vue';

const {
  showZiwoWindow,
  ziwoWindowData,
  onZiwoWindowShow,
  onZiwoWindowHide,
  onZiwoIframeLoad,
  makeZiwoCall
} = inject('ziwo-calls');
</script>

<template>
  <div
    v-show="showZiwoWindow"
    class="ziwo-window shadow-google bg-color-18"
    :class="[showZiwoWindow ? 'show' : '']"
  >
    <div class="w-100 window-top flex-between gap-3">
      <div>Powered by Ziwo...</div>
      <div class="flex-end gap-2">
        <FeatherIcon
          type="x"
          class="cursor-pointer"
          @click="onZiwoWindowHide"
        />
      </div>
    </div>
    <div class="ziwo-iframe">
      <iframe
        src="/ziwo.html"
        width="100%"
        height="100%"
        frameborder="0"
        allow="microphone; camera"
        @load="onZiwoIframeLoad"
      ></iframe>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ziwo-window {
  position: fixed;
  bottom: -200%;
  right: -200%;
  width: 460px;

  border-radius: 8px;
  border: 1px solid #515365 !important;

  .window-top {
    border-bottom: 1px solid #515365;
    padding: 14px 8px;
  }

  &.show {
    // animation: slideIn 0.5s forwards;
    z-index: 3000;
    bottom: 8px;
    right: 30px;
  }
}

.ziwo-iframe {
  height: 620px;
  width: 100%;
}
</style>
