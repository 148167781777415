import { createRouter, createWebHistory } from 'vue-router';

import defaultRoutes from '@/router/default-routes';
import recruitmentRoutes from '@/router/recruitment-routes';
import resumeSearchRoutes from '@/router/resume-search';
import appRoutes from '@/router/app-routes';
import logRoutes from '@/router/log-routes';
import settingRoutes from '@/router/setting-routes';
import subscriptionRoutes from '@/router/subscribtion-routes';
import masterViewRoutes from '@/router/master-view-routes';
import clientPortalRoutes from '@/router/client-portal-routes';
import reportRoutes from '@/router/report-routes';

import store from '@/store';
import useAuth from '@/composables/auth/useAuth';
import useEnv from '@/composables/app/useEnv';

const { environment } = useEnv();

const getRoutes = () => {
  let routes = [
    ...defaultRoutes,
    ...recruitmentRoutes,
    ...resumeSearchRoutes,
    ...appRoutes,
    ...logRoutes,
    ...settingRoutes,
    ...subscriptionRoutes,
    ...masterViewRoutes,
    ...clientPortalRoutes,
    ...reportRoutes
  ];

  if (environment !== 'production') {
    routes = routes.concat({
      path: '/dev/test',
      name: 'dev-test',
      component: () => import('@/views/DevTest.vue')
    });
  }

  return routes.concat({
    path: '/:pathMatch(.*)*',
    name: '404-not-found',
    component: () => import('@/views/pages/Error404.vue')
  });
};
const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes: getRoutes(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

const {
  verifyAccessToken,
  refreshAccessToken,
  roleMiddleWare,
  getCurrentRole
} = useAuth();

router.beforeEach(async (to, from, next) => {
  if (to.name === from.name && to.params === from.params) {
    return next();
  }

  let userVerified = await verifyAccessToken();
  if (!userVerified) userVerified = await refreshAccessToken();

  if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth');
    if (userVerified && !to.meta?.allowAuthenticated)
      return next({ name: 'Home' });
  } else {
    store.commit('setLayout', 'app');
    if (!userVerified) return next({ name: 'login' });
  }

  if (to.meta?.role) {
    const userCurrentRole = await getCurrentRole();

    const userHasPermission = roleMiddleWare(to.meta.role, userCurrentRole);
    if (!userHasPermission) return next({ name: 'error404' });
  }

  next(true);
});

export default router;
