import { ref } from 'vue';
import useAppConfig from '@/composables/app/useAppConfig';

const useSlider = (sliderIndex = 0) => {
	const { scrollToTop } = useAppConfig();
	const slider = ref(null);
	const sliderData = ref(null);
	const index = ref(sliderIndex);

	const showSlider = (name, data = null, scrollTop = false) => {
		sliderData.value = data;
		slider.value = name;

		if (scrollTop) {
			setTimeout(() => {
				scrollToTop();
			}, 100);
		}
	};
	const hideSlider = () => {
		slider.value = typeof slider.value === 'boolean' ? false : null;
		sliderData.value = null;
	};

	const updateSliderData = (items, itemKey = 'id') => {
		if (!sliderData.value || !items || !items.length) return;

		const item = items.value.find(
			item => item[itemKey] == sliderData.value[itemKey]
		);
		if (item) sliderData.value = item;
	};
	return {
		slider,
		sliderData,
		index,
		showSlider,
		hideSlider,
		updateSliderData,
	};
};

export default useSlider;
