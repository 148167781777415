import { ref, provide } from 'vue';
import useSlider from '@/composables/app/useSlider';
import useToast from '@/composables/app/useToast';

const useZiwo = () => {
  const {
    slider: showZiwoWindow,
    sliderData: ziwoWindowData,
    showSlider: onZiwoWindowShow,
    hideSlider: onZiwoWindowHide
  } = useSlider('ziwo-window');
  const { showDefaultToast } = useToast();
  const ziwoWindow = ref(null);

  const onZiwoIframeLoad = (event) => {
    const iframe = event.target;
    console.log('iframe loaded', iframe?.contentWindow);
    if (!iframe?.contentWindow) return;
    ziwoWindow.value = iframe.contentWindow;

    ziwoWindow.value.addEventListener('ziwo-call-all', (event) => {
      if (event?.detail?.type === 'ringing' && !showZiwoWindow.value) {
        onZiwoWindowShow(true);
      }
    });
  };

  const makeZiwoCall = async (phoneNumber) => {
    if (!ziwoWindow.value) return;
    if (!showZiwoWindow.value) onZiwoWindowShow(true);

    try {
      const call = await ziwoWindow.value?.ZIWO?.calls?.startCall(phoneNumber);
      return call;
    } catch (error) {
      if (error?.message && typeof error?.message === 'string') {
        // replace everything inside [] with empty string
        const message = error.message.replace(/\[.*?\]/g, '')?.trim();
        showDefaultToast('Failed to start call', 'danger', message);
      }
      return null;
    }
  };

  const ziwoCalls = {
    showZiwoWindow,
    ziwoWindowData,
    onZiwoWindowShow,
    onZiwoWindowHide,
    onZiwoIframeLoad,
    makeZiwoCall
  };

  provide('ziwo-calls', ziwoCalls);

  return ziwoCalls;
};

export default useZiwo;
