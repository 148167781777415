<script setup>
import { computed } from 'vue';
import Header from '@/components/layout/header.vue';
import Sidebar from '@/components/layout/sidebar.vue';
import Error404 from '@/views/pages/Error404.vue';
import ZiwoWindow from '@/views/ziwo/ZiwoWindow.vue';
import useZiwo from '@/composables/app/useZiwo';

import { useRoute } from 'vue-router';
import useAuth from '@/composables/auth/useAuth';
import useSettings from '@/composables/app/useSettings';

const props = defineProps({
  isDataSet: Boolean
});

const route = useRoute();
const { authMember } = useAuth();
const { teamSettings } = useSettings();

const canAccessRoute = computed(() => {
  if (typeof route?.meta?.middleware !== 'function') return true;
  return route.meta.middleware(authMember.value, teamSettings.value);
});

// initialize Ziwo functionality
useZiwo();
</script>

<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header></Header>
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      class="main-container"
      id="container"
      :class="[
        !$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
        $store.state.menu_style === 'collapsible-vertical'
          ? 'collapsible-vertical-mobile'
          : ''
      ]"
    >
      <!--  BEGIN OVERLAY  -->
      <div
        class="overlay"
        :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
      ></div>
      <div
        class="search-overlay"
        :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
      ></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div
        id="content"
        class="main-content"
      >
        <template v-if="isDataSet">
          <template v-if="teamSettings?.ziwo_calls?.enabled">
            <ZiwoWindow />
          </template>

          <router-view v-if="canAccessRoute" />
          <Error404 v-else />
        </template>

        <div v-else>
          <div class="initial-loader">
            <div class="fw-bold text-20px">Loading app data...</div>
            <div class="flex-start loading-container">
              <div
                v-for="x in 5"
                :key="x"
                class="circle bg-primary"
                :class="[`circle-${x}`]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>

<style scoped>
.initial-loader {
  height: calc(100vh - 108px);
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-container {
  gap: 8px;
}

.circle {
  animation: scaleUp 1s infinite ease-in-out;
  width: 14px;
  height: 14px;
  border-radius: 9999px;
}

/* Define keyframes for scaling animation */
@keyframes scaleUp {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

/* Apply different animation delays to each circle */
.circle-1 {
  animation-delay: 0s;
}
.circle-2 {
  animation-delay: 0.2s;
}
.circle-3 {
  animation-delay: 0.4s;
}
.circle-4 {
  animation-delay: 0.6s;
}
.circle-5 {
  animation-delay: 0.8s;
}
</style>
